import React, { ReactNode } from "react";
import { Footer, Logo } from "./App";
import './recipease.css';
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

function Recipease() {

  const personaExperienceImages = [
    {
      original: "Persona.png",
    },
    {
      original: "ExperienceMap.png",
    },
  ];

  const wireframingImages = [
    {
      original: "Wireframe1.png",
    },
    {
      original: "Wireframe2.png",
    },
    {
      original: "Wireframe3.png",
    },
    {
      original: "Wireframe4.png",
    },
    {
      original: "Wireframe5.png",
    },
  ]

  const taskSelectionImages = [
    {
      original: "TaskTable.png",
    },
    {
      original: "TaskFlow.png",
    },
  ]

  return (
    <div className="Recipease">
      <nav className={"nav"}>

        <div className={"navContents"}>
          <Logo/>

          <div className={"navLinks"}>
            <a className={"textButton"} href={"/#about_me"}>About</a>
            <a className={"textButton"} href={"/#work"}>Work</a>
            <a className={"textButton"} href={"mailto:yekatsuras@gmail.com"}>Contact</a>
          </div>
        </div>
      </nav>

      <div className={"rpTopSection"}>
        <img className={"fullSpan fullWidth"} src={"RecipeaseHeader.png"}
             alt={"header showing the Recipease logo and mockups"}/>
        <img className={"downArrowIndicator desktopOnly"} src={"DownArrow.png"} alt={"down arrow"} onClick={() => {
          window.scrollTo({ top: window.innerHeight * 0.8, behavior: 'smooth' })
        }}/>
      </div>

      <div className={"mainGrid rpSection"}>
        <div className={"grid36 t120"}>
          <p className={"sectionHeading"}>Overview</p>

          <div className={"t40"}>
            <p className={"folioBodyText"}>In spite of the increasing cost of eating out, Millennials and GenZs, the
              most
              financially vulnerable generations, dine out the most. <br/><br/>Recipease is an iOS recipe scheduling and
              curation app designed to encourage and inspire young homecooks to save money through home-cooking.</p>
          </div>
        </div>

        <div className={"grid713 t120 l32 studyTags"}>
          <p className={"sectionHeading transparent desktopOnly"}>.</p>
          <div className={"cardFitted orangeBackground t40"}>
            <p className={"folioBodyText"}><span className={"folioBodyTextBold"}>Role:</span> UX Researcher, UX Designer
            </p>
          </div>
          <div className={"cardFitted pinkBackground t24"}>
            <p className={"folioBodyText"}><span className={"folioBodyTextBold"}>Project Type:</span> Independent Case
              Study</p>
          </div>

          <div className={"cardFitted yellowBackground t24"}>
            <p className={"folioBodyText"}><span className={"folioBodyTextBold"}>Duration:</span> 10 weeks</p>
          </div>
        </div>

        <div className={"fullSpan"}>
          <a rel={"noreferrer"} target={"_blank"}
             href={"https://www.figma.com/proto/JLG3W02PyF6OSd3PDU5qHS/Capstone-HiFi?page-id=0%3A1&type=design&node-id=1-2&viewport=88%2C428%2C0.14&t=C2y1HufUQhRFbr9w-1&scaling=scale-down&starting-point-node-id=1%3A2&show-proto-sidebar=1&mode=design"}
             className={"button smallButton t100 mauto"}>View Prototype</a>
        </div>
      </div>

      <div className={"rpSection mainGrid"}>
        <div className={"fullSpan headingBanner t100 orangeBackground mainGrid mainGridNoPadding"}>
          <h1 className={"sectionHeading gridOffset1"}>Process</h1>
        </div>
      </div>

      <div className={"mainGrid rpSection"}>
        <div className={"grid80 t120"}>

          <p className={"folioBodyText"}>At the onset of this project, I opted for the more structured approach of the
            <span className={"folioBodyTextBold"}> Double Diamond</span> design process, a design methodology that
            encourages exploration (diverging) that is then distilled into
            an optimal solution (converging).</p>

          <img className={"fullSpan fullWidth t80"} src={"DoubleDiamond.png"}
               alt={"The double diamond design process"}/>
        </div>
      </div>

      <div className={"rpSection mainGrid"}>
        <div className={"fullSpan headingBanner t100 pinkBackground mainGrid mainGridNoPadding"}>
          <h1 className={"sectionHeading gridOffset1"}>Problem Space</h1>
        </div>
      </div>

      <div className={"rpSection mainGrid"}>
        <div className={"grid80 t120"}>
          <p className={" folioBodyText "}>Over <span className={"folioBodyTextBold"}>51% of Canadians are living paycheque to paycheque</span>,
            the majority of whom are Millennials and GenZ. Despite being the most financially vulnerable, these are the
            generations that are most driven toward convenience-driven consumption patterns. In spite of the hefty
            markup
            of eating out and a strong, self-reported desire to cook at home more to save money, <span
              className={"folioBodyTextBold"}>Millennials and GenZ eat out the most.</span> Furthermore, they know fewer
            recipes on average than older generations.

            <br/><br/>What would inspire these younger generations to cook at home more so they can save money?
          </p>
        </div>
      </div>

      <div className={"rpSection mainGrid"}>
        <div className={"fullSpan headingBanner t120 yellowBackground mainGrid mainGridNoPadding"}>
          <h1 className={"sectionHeading gridOffset1"}>Research & Synthesis</h1>
        </div>
      </div>

      <div className={"rpSection mainGrid"}>
        <div className={"grid80 t120"}>
          <p className={" folioBodyText "}>In order to arrive at better understanding of the problem at hand, I dove
            deeper into <span className={"folioBodyTextBold"}>secondary research</span> and discovered some startling
            facts.
          </p>

          <div className={"cardSet t80"}>
            <Card className={"yellowBackground"} title={"20%"} content={"Price increase of groceries since 2021"}/>
            <Card className={"pinkBackground"} title={"300%"} content={"Average mark-up at restaurants"}/>
            <Card className={"orangeBackground"} title={"6"} content={"Average # of recipes millennials know"}/>
          </div>

          <div className={"t120"}>
            <p className={" folioBodyText "}>I discovered that food has never been more expensive. Groceries have risen
              over 20% over the last 3 years and the average price mark-up at restaurants, to account for the price of
              food and other overheads, is 300%.<br/><br/>

              What’s worse,<span className={"folioBodyTextBold"}> Millennials and GenZ make up the majority of the Canadians living paycheque to paycheque.</span> These
              same generations, as aforementioned, eat out the most, despite their desire to cook at home more to save
              money.<br/><br/>

              This means that <span className={"folioBodyTextBold"}>the demographic with the least financial stability is paying the most for food.</span>
            </p>
          </div>
        </div>
      </div>


      <div className={"rpSection mainGrid"}>
        <div className={"grid80 t120"}>
          <h1 className={"sectionHeading"}>Interviews</h1>

          <div className={"t80"}>
            <p className={"folioBodyText t24"}>The data was compelling. Still, I wanted to gain further insight straight
              from
              the source so that I could better understand the issue at a human, attitudinal level. In order to do so, I
              conducted a round of <span className={"folioBodyTextBold"}>decontextualized interviews</span> with 3
              people who made up my target demographic (millennials and GenZs living in Ontario).
              <br/><br/>
              My <span className={"folioBodyTextBold"}>interview objectives</span> were as follows:.</p>
          </div>
          <div className={"cardSet t80"}>
            <Card className={"orangeBackground"} title={"1"} content={"Learn the main motivations for eating out"}/>
            <Card className={"orangeBackground"} title={"2"}
                  content={"Discover any impediments that deter home-cooking"}/>
            <Card className={"orangeBackground"} title={"3"} content={"Understand eating habits and their causes"}/>
          </div>

          <div className={"fullSpan"}>
            <a rel={"noreferrer"} target={"_blank"}
               href={"https://docs.google.com/document/d/1tXzKJ-EdILvBWAjFFMMydrNFfBonCcark_kyzt_azMc/edit"}
               className={"button smallButton t100 mauto"}>Interview Guide</a>
          </div>
        </div>

        <div className={"grid80 t120"}>
          <h1 className={"sectionHeading"}>Synthesizing</h1>

          <div className={"t80"}>
            <p className={"folioBodyText t24"}>Using <span className={"folioBodyTextBold"}>Affinity Mapping</span>, I
              then synthesized the raw interview data into <span className={"folioBodyTextBold"}>pain points, motivations, and behaviours.</span> I
              then grouped them thematically to find a common thread and see what insight I could gain. </p>
          </div>

          <img className={"fullWidth grid90 t80"} src={"AffinityMapping.png"}
               alt={"notes from the affinity mapping"}/>
        </div>


        <div className={"grid80 t120"}>
          <h1 className={"sectionHeading"}>Themes & Insights</h1>

          <div className={"t80"}>
            <p className={"folioBodyText t24"}>The following were the <span className={"folioBodyTextBold"}>core themes and insights</span> that
              emerged through Affinity Mapping:</p>
          </div>
          <div className={"fullSpan largeCardGroup t80"}>
            <LargeCard className={"yellowBackground"} title={"Accessibility"}
                       content={"Finding recipes that are good quality and authentic is not easy, which makes meal diversity at home difficult."}/>
            <LargeCard className={"pinkBackground"} title={"Exposure"}
                       content={"GenZs and Millennials reported they found it difficult expanding on their culinary ability, particularly when cooking outside of their culture."}/>
            <LargeCard className={"orangeBackground"} title={"Cost"}
                       content={"Expenditure of time, money, and energy was by far the biggest concern. Interviewees were motivated to eat in to save money but ordered out when they struggled with time and energy."}/>
          </div>
          <div className={"t80"}>
            <p className={"folioBodyText t24"}>The <span className={"folioBodyTextBold"}>key theme</span> I chose to
              focus on was <span className={"folioBodyTextBold"}>cost</span>, as it had the most pain points that would
              be integral to ideating a solution.</p>
          </div>
        </div>
      </div>

      <div className={"rpSection mainGrid"}>

        <div className={"grid80 t120"}>
          <h1 className={"sectionHeading"}>Persona & Experience Mapping</h1>

          <div className={"t80"}>
            <p className={"folioBodyText t24"}>Utilizing my research and interview findings, I created a persona: an
              amalgam of the pain points, motivations, and goals, as well as insights. Creating a persona helped me to
              assure I was keeping my users in mind when making decisions throughout the rest of the design process.
              <br/><br/>
              I then leveraged the persona to build an experience map, in order to put myself in their shoes and
              discover the ideal time for design intervention. It appeared the most suitable time was at or before the
              time of cooking.</p>
          </div>

        </div>
      </div>

      <div className={"rpSection mainGrid"}>
        <div className={"grid80 fullSpan fullWidth noOverflow t120"}>
          <ImageGallery items={personaExperienceImages} showFullscreenButton={false} showBullets={false}
                        showPlayButton={false}/>;
        </div>

        <div className={"grid80 t80"}>
          <p className={"folioBodyText t24"}>Utilizing all of my new findings and referencing my persona and experience
            map, <span className={"folioBodyTextBold"}>I refined my initial guiding question to better inform my future design choices.</span>
          </p>
        </div>
      </div>

      <div className={"fullWidth yellowBackground t120"}>

        <h1 className={"sectionHeading howMightWe"}>How might we help young people in Ontario save money by making home
          cooking more accessible?</h1>
      </div>

      <div className={"rpSection mainGrid"}>
        <div className={"fullSpan headingBanner t120 orangeBackground mainGrid mainGridNoPadding"}>
          <h1 className={"sectionHeading gridOffset1"}>Ideation</h1>
        </div>
      </div>

      <div className={"rpSection mainGrid"}>
        <div className={"grid80 t120"}>
          <h1 className={"sectionHeading"}>Task Selection</h1>
          <div className={"t80"}>
            <p className={"folioBodyText t24"}>Bearing in mind my design question, my persona, and potential moments of
              design intervention, I crafted 30 user stories and grouped them thematically and functionally into epics.
              This is an integral step in assuring a product is in line with a user’s needs and determining its
              functionality.
              <br/><br/>
              I selected the epic most in line with my how might we, setting recipe preferences, and created a task flow
              to visually illustrate how the selected epic functions.

            </p>
          </div>
          <div className={"grid80 fullSpan fullWidth noOverflow t120"}>
            <ImageGallery items={taskSelectionImages} showFullscreenButton={false} showBullets={false}
                          showPlayButton={false}/>;
          </div>
        </div>

        <div className={"grid80 t120"}>
          <h1 className={"sectionHeading"}>Sketching out a Solution</h1>
          <div className={"t80"}>
            <p className={"folioBodyText t24"}>Utilizing the task flow, I sought out UI inspiration and then set out
              sketching what the task flow would look like in product form. I began with several exploratory sketches
              and distilled those ideas into solution sketches.
            </p>
          </div>
        </div>

        <img className={"fullSpan fullWidth t80 imageMultiply"} src={"SolutionSketches.png"}
             alt={"Sketches of the proposed app solution"}/>
      </div>

      <div className={"rpSection mainGrid"}>
        <div className={"fullSpan headingBanner t120 pinkBackground mainGrid mainGridNoPadding"}>
          <h1 className={"sectionHeading gridOffset1"}>Iteration</h1>
        </div>
      </div>

      <div className={"rpSection mainGrid"}>
        <div className={"grid80 t120"}>
          <h1 className={"subheading"}>Wireframing</h1>
          <div className={"t80"}>
            <p className={"folioBodyText t24"}>Before moving on to high-fidelity, it was imperative that the usability
              of my proposed design and task flow be tested.
              <br/><br/>
              In order to do so, I first created mid-fidelity wireframes based off of my solution sketches.
            </p>
          </div>
        </div>
      </div>

      <div className={"rpSection mainGrid"}>
        <div className={"fullSpan overflowScroll t120"}>
          <img className={"scrollingImages"} src={"EarlyMockups.png"} alt={"early mockups of the app"}/>
        </div>

        <div className={"grid80 t120"}>
          <p className={"folioBodyText t24"}>I then <span className={"folioBodyTextBold"}>conducted 2 rounds of user testing, testing 10 different target users in total.</span>
            <br/><br/>
            Users went through the task flow without issue. However, feedback was still crucial in improving the
            product. With user feedback, the following changes were made:</p>
        </div>

        <div className={"grid80 fullSpan fullWidth noOverflow t80"}>
          <ImageGallery items={wireframingImages} showFullscreenButton={false} showBullets={false}
                        showPlayButton={false}/>;
        </div>
      </div>

      <div className={"rpSection mainGrid"}>
        <div className={"fullSpan headingBanner t120 yellowBackground mainGrid mainGridNoPadding"}>
          <h1 className={"sectionHeading gridOffset1"}>Brand Identity</h1>
        </div>
      </div>

      <div className={"rpSection mainGrid"}>
        <div className={"grid80 t120"}>
          <h1 className={"sectionHeading"}>Moodboard</h1>
          <div className={"t80"}>
            <p className={"folioBodyText t24"}>Having tested the usability of my wireframes, I next wanted to set a
              foundation for the transition to high-fidelity by developing a brand. I leveraged the persona’s wants and
              needs to develop an idea of what my brand identity should convey.
              <br/><br/>
              I started by making a moodboard, based on the following adjectives: comforting, quirky, nostalgic,
              youthful, and dynamic.</p>
          </div>
        </div>

        <div className={"grid80 t120"}>
          <h1 className={"sectionHeading"}>Color Palette</h1>
          <div className={"t80"}>
            <p className={"folioBodyText t24"}>Having extracted colours from my moodboard, I played around with them
              until I felt they were a good representation of the brand and meshed well together.
              <br/><br/>
              I knew I wanted orange, as it was a warm, welcoming colour with a retro feel. I therefore used a bluer
              purple, as it’s a complimentary colour with a tinge of playfulness.
              <br/><br/>
              I then used them to experiment with my wireframes, trying to discover the feel that I believed would be
              most representative of the brand. From there, the colours evolved into this palette.</p>
          </div>
          <div className={"t80"}>
            <img className={"fullWidth"} src={"Moodboard.png"}
                 alt={"A mood board with oranges, reds, blues and purples, with soft pastels"}/>
          </div>
        </div>


        <div className={"grid80 t120"}>
          <h1 className={"sectionHeading"}>Wordmark</h1>
          <div className={"t80"}>
            <p className={"folioBodyText t24"}>I wanted the wordmark to exude a bit of charm and be easily readable. I
              also wanted it to be a playful homage to cooking. The names that I therefore played with were “recipease”
              (recipe + ease = recipes) and “dish, please”, a more humourous approach, aimed at appealing to the younger
              demographic the product is catered toward. </p>
          </div>
          <div className={"t80"}>
            <img className={"fullWidth"} src={"Wordmark.png"}
                 alt={"A mood board with oranges, reds, blues and purples, with soft pastels"}/>
          </div>
          <div className={"t80"}>
            <p className={"folioBodyText t24"}>I decided on Recipease, as it was more visually fluid. opting for a sans
              serif for readability but still allowing for playfulness by incorporating a tilted ladle in lieu of the
              letter p. I then used the ladle as an app icon. This was the final result:</p>
          </div>
          <div className={"flexRow3"}>
            <img className={"fullWidth t80"} src={"Workmark1.png"}
                 alt={"Wordmark 1"}/>
            <img className={"fullWidth t80"} src={"Wordmark2.png"}
                 alt={"Wordmark 2"}/>
            <img className={"fullWidth t80"} src={"Wordmark3.png"}
                 alt={"Wordmark 3"}/>
          </div>
        </div>

        <div className={"grid80 t120"}>
          <h1 className={"sectionHeading"}>Typestyle</h1>
          <div className={"t80"}>
            <p className={"folioBodyText t24"}>The typeface I chose for the product is Outfit, a sans-serif Google font.
              I wanted the text to mesh well with the branding but not be distracting; it was extremely important to me
              that it was legible above all else. </p>
          </div>
          <div className={"t80"}>
            <img className={"fullWidth"} src={"Typography.png"}
                 alt={"An example of the text and font weights used"}/>
          </div>
        </div>

        <div className={"grid80 t120"}>
          <h1 className={"sectionHeading"}>UI Library</h1>
          <div className={"t80"}>
            <p className={"folioBodyText t24"}>Now that my branding was established, I built a UI library using the
              atomic model of design systems. Doing so would also expedite my workflow and ensure that consistency was
              maintained in my design.</p>
          </div>
          <div className={"t80"}>
            <img className={"fullWidth"} src={"UI library.png"}
                 alt={"An example of the text and font weights used"}/>
          </div>
        </div>

        <div className={"fullSpan"}>
          <a rel={"noreferrer"} target={"_blank"}
             href={"https://www.figma.com/proto/JLG3W02PyF6OSd3PDU5qHS/Capstone-HiFi?page-id=0%3A1&type=design&node-id=1-2&viewport=88%2C428%2C0.14&t=C2y1HufUQhRFbr9w-1&scaling=scale-down&starting-point-node-id=1%3A2&show-proto-sidebar=1&mode=design"}
             className={"button smallButton t100 mauto"}>View Full UI Library</a>
        </div>
      </div>


      <div className={"rpSection mainGrid"}>
        <div className={"fullSpan headingBanner t120 orangeBackground mainGrid mainGridNoPadding"}>
          <h1 className={"sectionHeading gridOffset1"}>Final Product</h1>
        </div>
      </div>

      <div className={"rpSection mainGrid"}>
        <div className={"grid80 t120"}>
          <p className={"folioBodyText"}>An app that curates recipes, based on the user’s preferences and lets them
            schedule which recipes they make by day of the week. Additionally, it highlights recipes by speed,
            difficulty, and cost per serving, aiming to incentivize users by showing them how much money they’ve saved
            through home-cooking.
          </p>
        </div>

        <div className={"fullSpan overflowScroll t120"}>
          <img className={"scrollingImages"} src={"Mockups.png"} alt={"mockups of the app"}/>
        </div>

        <div className={"fullSpan t80"}>
          <a rel={"noreferrer"} target={"_blank"}
             href={"https://www.figma.com/proto/JLG3W02PyF6OSd3PDU5qHS/Capstone-HiFi?page-id=0%3A1&type=design&node-id=1-2&viewport=88%2C428%2C0.14&t=C2y1HufUQhRFbr9w-1&scaling=scale-down&starting-point-node-id=1%3A2&show-proto-sidebar=1&mode=design"}
             className={"button smallButton mauto"}>View Prototype</a>
        </div>
      </div>

      <div className={"rpSection mainGrid"}>
        <div className={"fullSpan headingBanner t120 pinkBackground mainGrid mainGridNoPadding"}>
          <h1 className={"sectionHeading gridOffset1"}>Marketing</h1>
        </div>
      </div>

      <div className={"rpSection mainGrid"}>
        <div className={"grid80 t120"}>
          <p className={"folioBodyText"}>Now that I had a product, I explored means of growing a user-base. I created a
            responsive marketing site on both website and mobile, aiming to best showcase the product and clearly
            communicate Recipease’s value proposition.
          </p>
          <div>
            <img className={"fullWidth t120"} src={"MarketingWebsite.png"}
                 alt={"A Mood board with oranges, reds, blues and purples, with soft pastels"}/>
          </div>
          <div>
            <a rel={"noreferrer"} target={"_blank"}
               href={"https://www.figma.com/proto/lzTxZ2H5XMSlq2IbvOa1dq/Capstone-Design-System?page-id=8266%3A18137&type=design&node-id=8266-18138&viewport=442%2C136%2C0.16&t=U32I7epajm5aVYkb-1&scaling=min-zoom&mode=design"}
               className={"button smallButton mauto t80"}>View Desktop</a>
            <a rel={"noreferrer"} target={"_blank"}
               href={"https://www.figma.com/proto/lzTxZ2H5XMSlq2IbvOa1dq/Capstone-Design-System?page-id=8266%3A19322&type=design&node-id=8266-19323&viewport=476%2C1479%2C0.7&t=QMrrtTUP9IyAjRRA-1&scaling=scale-down&mode=design"}
               className={"button smallButton mauto t40"}>View Mobile</a>
          </div>
        </div>
      </div>

      <div className={"rpSection mainGrid"}>
        <div className={"fullSpan headingBanner t120 yellowBackground mainGrid mainGridNoPadding"}>
          <h1 className={"sectionHeading gridOffset1"}>Next Steps & Key Learnings</h1>
        </div>
      </div>

      <div className={"rpSection mainGrid"}>

        <div className={"grid80 t120"}>
          <h1 className={"sectionHeading gridOffset1"}>Thinking Ahead</h1>
          <div className={"t80 bulletGroup"}>
            <BulletPoint>In the future, I’d like to build out additional flows. For one, I’d like to add a way of
              rewarding users for completing recipes and tracking the money they’ve saved by eating out less, adding a
              bit of gamification to Recipease to make it more sticky.</BulletPoint>
            <BulletPoint>Using the Tarot Cards of Tech, I’d like to consider the future impact of Recipease. For
              instance, what would happen if 100 million people used my product?
              <br/><br/>
              I’d like Recipease to evolve into a sort of recipe social media, where users can share their own authentic
              recipes. The more users, the more content. </BulletPoint>

          </div>

          <div className={""}>
            <img className={"fullWidth t80"} src={"Tarot.png"}
                 alt={"A tarot card reading 'The Smash Hit'"}/>
          </div>
        </div>


        <div className={"grid80 t120"}>
          <h1 className={"sectionHeading gridOffset1"}>Takeaways</h1>
          <div className={"t80 bulletGroup"}>
            <BulletPointWithTitle title={"Lay your Groundwork"}>Whether through research, sketching, or inspiration, it
              is essential to build a foundation before jumping into design.</BulletPointWithTitle>
            <BulletPointWithTitle title={"Never forget your user"}>Throughout the design process, you must always think
              back to your user. Are their needs being met by the choices you make?</BulletPointWithTitle>
            <BulletPointWithTitle title={"Iterate"}>Try, try again. Fail, fail again. There is no progress without
              trying and failing. For this reason, user testing was invaluable.</BulletPointWithTitle>
          </div>
        </div>
      </div>

      <div className={"rpSection mainGrid"}>
        <div className={"fullSpan headingBanner t120 orangeBackground mainGrid mainGridNoPadding"}>
          <h1 className={"sectionHeading gridOffset1"}>Thanks for your time!</h1>
        </div>
      </div>

      <Footer/>

      <div className={"t80"}></div>
    </div>
  );
}

function Card(props: {
  title: string, content
    :
    string, className?: string
}) {

  const className = props.className ? "card " + props.className : "card"

  return (
    <div className={className}>
      <h2 className={"cardTitle"}>{props.title}</h2>
      <div className={"t24"}>
        <p className={"folioBodyTextBold"}>{props.content}</p>
      </div>
    </div>
  );
}


function LargeCard(props: {
  title: string, content
    :
    string, className?: string
}) {

  const className = props.className ? "largeCard " + props.className : "largeCard"

  return (
    <div className={className}>
      <h2 className={"folioSubheading textAlignCenter"}>{props.title}</h2>
      <div className={"t24"}>
        <p className={"folioBodyText t24"}>{props.content}</p>
      </div>
    </div>
  );
}

function BulletPoint(props: {
  children: ReactNode
}) {
  return (
    <div className={"bulletPoint"}>
      <img className={"bullet"} src={"Bullet.png"} alt={"bullet point"}/>
      <p className={"folioBodyText"}>{props.children}</p>
    </div>
  );
}

function BulletPointWithTitle(props: {
  title: string, children: ReactNode
}) {
  return (
    <div className={"bulletPoint"}>
      <img className={"bullet"} src={"Bullet.png"} alt={"bullet point"}/>
      <div>
        <h1 className={"folioSubheading"}>{props.title}</h1>
        <div className={"t24"}>
          <p className={"folioBodyText"}>{props.children}</p>
        </div>
      </div>
    </div>
  );
}

export default Recipease;