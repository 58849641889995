import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Recipease from "./recipease";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Superpatch from "./superpatch";
import Shopify from "./shopify";
import Goodreads from "./goodreads";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <React.StrictMode>
        <App />
      </React.StrictMode>
    )
  },
  {
    path: "recipease",
    element: (
      <React.StrictMode>
        <Recipease />
      </React.StrictMode>
    )
  },
  {
    path: "superpatch",
    element: (
      <React.StrictMode>
        <Superpatch />
      </React.StrictMode>
    )
  },
  {
    path: "shopify",
    element: (
      <React.StrictMode>
        <Shopify />
      </React.StrictMode>
    )
  },
  {
    path: "goodreads",
    element: (
      <React.StrictMode>
        <Goodreads />
      </React.StrictMode>
    )
  },
]);

root.render(
  <RouterProvider router={router} />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
