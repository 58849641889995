import React, { ReactNode } from "react";
import { Footer, Logo } from "./App";
import './goodreads.css';
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";

function Goodreads() {
  const finalSectionRef = React.useRef<HTMLDivElement>(null);

  const evalImages = [
    {
      original: "Eval1.png",
    },
    {
      original: "Eval2.png",
    },
    {
      original: "Eval3.png",
    },
    {
      original: "Eval4.png",
    },
    {
      original: "Eval5.png",
    },
    {
      original: "Eval6.png",
    },
  ];

  const amendmentsImages = [
    {
      original: "Amendment1.png",
    },
    {
      original: "Amendment2.png",
    },
    {
      original: "Amendment3.png",
    },
    {
      original: "Amendment4.png",
    },
    {
      original: "Amendment5.png",
    },
    {
      original: "Amendment6.png",
    },
  ];


  return (
    <div className="Recipease">
      <nav className={"nav"}>

        <div className={"navContents"}>
          <Logo/>

          <div className={"navLinks"}>
            <a className={"textButton"} href={"/#about_me"}>About</a>
            <a className={"textButton"} href={"/#work"}>Work</a>
            <a className={"textButton"} href={"mailto:yekatsuras@gmail.com"}>Contact</a>
          </div>
        </div>
      </nav>

      <div className={"rpTopSection"}>
        <img className={"fullSpan fullWidth topSectionImage"} src={"GoodreadsHeader.png"}
             alt={"header showing the Recipease logo and mockups"}/>
        <img className={"downArrowIndicator desktopOnly"} src={"DownArrowPurple.png"} alt={"down arrow"}
             onClick={() => {
               window.scrollTo({ top: window.innerHeight * 0.8, behavior: 'smooth' })
             }}/>
      </div>

      <div className={"mainGrid rpSection"}>
        <div className={"grid36 t120"}>
          <p className={"sectionHeading"}>Overview</p>

          <div className={"t40"}>
            <p className={"folioBodyText"}>Alex Koch-Fitsialos and I conducted a heuristic evaluation of Goodreads, the
              largest online book community in the world. We pointed out usability issues, rated them by severity,
              and then presented our proposed design changes that would improve usability.</p>
          </div>
        </div>

        <div className={"grid813 t120 l32 studyTags"}>
          <p className={"sectionHeading transparent desktopOnly"}>.</p>
          <div className={"cardFitted greenGoodreadsBackground t40"}>
            <p className={"folioBodyText"}><span className={"folioBodyTextBold"}>Role:</span> UX Designer / UX
              Researcher
            </p>
          </div>
          <div className={"cardFitted greenGoodreadsBackground t24"}>
            <p className={"folioBodyText"}><span className={"folioBodyTextBold"}>Project Type:</span> Hackathon</p>
          </div>

          <div className={"cardFitted greenGoodreadsBackground t24"}>
            <p className={"folioBodyText"}><span className={"folioBodyTextBold"}>Duration:</span> 24 hours</p>
          </div>
        </div>

        <div className={"fullSpan"}>
          <a onClick={() => {
            finalSectionRef.current?.scrollIntoView({ behavior: 'smooth' })
          }}
             className={"button smallButton t100 mauto purpleGoodreadsBackground whiteColor"}>Skip to Redesign</a>
        </div>
      </div>

      <div className={"rpSection mainGrid"}>
        <div className={"fullSpan headingBanner t100 greenGoodreadsBackground mainGrid mainGridNoPadding"}>
          <h1 className={"sectionHeading gridOffset1"}>Foundations</h1>
        </div>
      </div>

      <div className={"mainGrid rpSection"}>
        <div className={"grid80 t120"}>
          <h1 className={"sectionHeading gridOffset1"}>A Little About Goodreads</h1>
          <div className={"t80"}>
            <p className={"folioBodyText "}>Goodreads is an Amazon subsidiary that allows users to track and review
              books, as well as providing curated book recommendations. They tout over 150 million members, making them
              the <span className={"folioBodyTextBold"}>largest online book community in the world.</span>
              <br/><br/>We opted to
              conduct a heuristic evaluation on the Goodreads <span
                className={"folioBodyTextBold"}>onboarding process</span> from the perspective of a new user. We
              chose this step as it is Goodreads’ first impression and integral to building and maintaining their
              user-base. We focused on the <span className={"folioBodyTextBold"}>last three steps</span> in particular,
              as they had the most severe usability
              infringements.
            </p>
          </div>
          <div className={"t80"}>
            <img className={"fullWidth"} src={"GoodreadsSteps.png"}
                 alt={"Step 1: Connect with friends. Step 2: Set a reading Goal. Step 3: Select your favorite genres. Step 4: Rate books you've read. Step 5: Receive recommendations."}/>
          </div>
        </div>
      </div>

      <div className={"mainGrid rpSection"}>
        <div className={"grid80 t120"}>
          <h1 className={"sectionHeading gridOffset1"}>How Do We Measure Usability?</h1>
          <div className={"t80"}>
            <p className={"folioBodyText "}>A heuristic evaluation is a method used to test product usability and, more
              importantly, find <span className={"folioBodyTextBold"}>usability problems</span> in user interface
              design. It is conducted using the 10 Usability
              Heuristics established by the Nielsen Norman Group, pioneers of UX industry. The heuristics are then
              graded on a scale 0 to 4, with 0 being no usability issues and 4 being catastrophic user experience
              violations.
            </p>
          </div>
          <div className={"t80"}>
            <img className={"fullWidth"} src={"GoodreadsScale.png"}
                 alt={"Scale 0 - 4. 0 is No issue. 4 is catastrophic."}/>
          </div>
        </div>
      </div>

      <div className={"mainGrid rpSection"}>
        <div className={"grid80 t120"}>
          <h1 className={"sectionHeading gridOffset1"}>10 Usability Heuristics</h1>
          <div className={"t80"}>
            <p className={"folioBodyText "}>We focused on the following <span className={"folioBodyTextBold"}>5 of the 10</span> heuristics
              during our revisions of
              Goodreads, as they had the <span className={"folioBodyTextBold"}>most severe usability violations.</span>
            </p>
          </div>
          <div className={"t80 heuristicGrid"}>
            <TitledHeuristic title={"1. Visibility of System Status"}
                             detail={"System informs the user about what is going on"}/>
            <Heuristic detail={"2. Match Between System & the Real World"}/>
            <TitledHeuristic title={"3. User Control & Freedom"} detail={"Supports forward and backward navigation"}/>
            <TitledHeuristic title={"4. Consistency & Standards"} detail={"Follows platform conventions"}/>
            <TitledHeuristic title={"5. Error Prevention"} detail={"Preemptively prevents a problem from occurring"}/>
            <Heuristic detail={"6. Recognition Rather than Recall"}/>
            <Heuristic detail={"7. Flexibility & Efficiency of Use"}/>
            <TitledHeuristic title={"8. Aesthetic & Minimalist Design"}
                             detail={"Provide only necessary information in an efficient way"}/>
            <Heuristic detail={"9. Recognize, Diagnose & Recover from Errors"}/>
            <Heuristic detail={"10. Help & Documentation"}/>

          </div>
        </div>
      </div>

      <div className={"rpSection mainGrid"}>
        <div className={"fullSpan headingBanner t100 greenGoodreadsBackground mainGrid mainGridNoPadding"}>
          <h1 className={"sectionHeading gridOffset1"}>The Heuristic Evaluation</h1>
        </div>
      </div>

      <div className={"rpSection mainGrid"}>
        <div className={"grid80 t120"}>
          <p className={" folioBodyText "}>After conducting our heuristic investigation on the Goodreads onboarding
            process, we <span className={"folioBodyTextBold"}>unconvered the following usability violations:</span>
          </p>
        </div>

        <div className={"grid80 fullSpan fullWidth noOverflow t80"}>
          <ImageGallery items={evalImages} showFullscreenButton={false} showBullets={false}
                        showPlayButton={false}/>;
        </div>
      </div>

      <div className={"rpSection mainGrid"}>
        <div className={"fullSpan headingBanner t100 greenGoodreadsBackground mainGrid mainGridNoPadding"}>
          <h1 className={"sectionHeading gridOffset1"}>The Redesign</h1>
        </div>
      </div>

      <div className={"rpSection mainGrid"}>
        <div className={"grid80 t120"}>
          <p className={" folioBodyText "}>Before setting about implementing changes that would fix usability issues, we
            referred to the <span className={"folioBodyTextBold"}>Goodreads style guide</span> to ensure design
            consistency. We did, however, propose the <span className={"folioBodyTextBold"}>adoption of
              a new primary colour</span> that would improve usability by drawing the eye to important information for
            the user,
            while staying true to the brand’s sophistication.
          </p>
        </div>


        <div className={"grid80 t80"}>
          <img className={"fullWidth"} src={"GoodreadsStyleguide.png"}
               alt={"A styleguide with new colors and font suggestions"}/>
        </div>

        <div className={"grid80 t120"}>
          <p className={" folioBodyText "}>Having established a style guide to adhere to, we set out implementing the
            solutions we proposed. <span
              className={"folioBodyTextBold"}>These were the following amendments we made:</span>
          </p>
        </div>

        <div className={"grid80 fullSpan fullWidth noOverflow t80"}>
          <ImageGallery items={amendmentsImages} showFullscreenButton={false} showBullets={false}
                        showPlayButton={false}/>;
        </div>
      </div>

      <div className={"rpSection mainGrid"} ref={finalSectionRef}>
        <div className={"fullSpan headingBanner t100 greenGoodreadsBackground mainGrid mainGridNoPadding"}>
          <h1 className={"sectionHeading gridOffset1"}>The Final Result</h1>
        </div>
      </div>

      <div className={"rpSection mainGrid"}>
        <div className={"grid80 t80"}>
          <div className={"macbookHolder"}>
            <img className={"fullWidth"} src={"Macbook.png"}
                 alt={"A macbook to hold the video player"}/>
            <img className={"macbookHeader"} src={"MacbookHeader.png"}
                 alt={"A macbook to hold the video player"}/>
            <video className={"videoPlayer macbookVideoPlayer"} controls>
              <source
                src={"https://s3-figma-videos-production-sig.figma.com/video/1288168960228183621/TEAM/c3ef/bd90/-fb45-469c-9ae9-65e69f5e991d?Expires=1723420800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=nPktUkPg7A9k-LqHUhVYaJmRS1S4xUnWFg5NiRwqeGLJgpPU-ek15vP23iBTjLpY4B3mgm2lTPJWRFLhlax8fXZewBYGvYELsCEELWngs0q8zupedt2zuuHIC~8axcJcE7lE5Hese0WOiT406KkKd6O2BB~H5shP2EaynSexDG-jkUpCuLnXDp1i0laai95OoKrKkEzhydQBE5gyW1KNJ4KydkAHlosaueVncCI6UoBr9KviN~xXiBFP7msye6vcoXZJ6oVXdVJMZ6ZEDkZiiikzvpl-IZqZp~vyMOwvsTJsI29g9POUZLGMPlrbdLYIKr7aw2QUJYm3hoYmqmOaQA__"}
                type={"video/mp4"}/>
            </video>
          </div>
        </div>
      </div>

      <div className={"rpSection mainGrid"}>
        <div className={"fullSpan headingBanner t100 greenGoodreadsBackground mainGrid mainGridNoPadding"}>
          <h1 className={"sectionHeading gridOffset1"}>Next Steps</h1>
        </div>
      </div>

      <div className={"rpSection mainGrid"}>
        <div className={"grid80 t120"}>
          <div className={"bulletGroup"}>
            <BulletPointWithTitle title={"Conduct a site-wide heuristic evaluation"}>The next logical step would be to
              conduct a <span className={"folioBodyTextBold"}>site-wide heuristic evaluation</span> of Goodreads as a
              whole to ensure usability across the
              platform.</BulletPointWithTitle>
            <BulletPointWithTitle title={"Prioritize Solutions"}>Having finished the site-wide heuristic evaluation, we
              would next <span className={"folioBodyTextBold"}>plot proposed solutions on a design prioritization matrix.</span> This
              helps determine the solutions
              that can be implemented quickly that have the highest user value.</BulletPointWithTitle>
            <BulletPointWithTitle title={"Implement changes"}>Referencing the design prioritization matrix, we would
              next <span className={"folioBodyTextBold"}>implement the changes proposed</span>, beginning with the low
              hanging fruit i.e. the <span className={"folioBodyTextBold"}>most impactful to users</span>
              and the easiest to implement.</BulletPointWithTitle>
          </div>
        </div>
      </div>

      <div className={"rpSection mainGrid"}>
        <div className={"fullSpan headingBanner t120 greenGoodreadsBackground mainGrid mainGridNoPadding"}>
          <h1 className={"sectionHeading gridOffset1"}>Thanks for your time!</h1>
        </div>
      </div>

      <Footer/>

      <div className={"t80"}></div>
    </div>
  );
}

function TitledHeuristic(props: {
  title: string,
  detail: string,
}) {
  return (
    <div className={"heuristic purpleGoodreadsBackground"}>
      <h2 className={"heuristicTitle whiteColor"}>{props.title}</h2>
      <p className={"heuristicBody whiteColor"}>{props.detail}</p>
    </div>
  );
}

function Heuristic(props: {
  detail: string,
}) {
  return (
    <div className={"heuristic beigeGoodreadsBackground"}>
      <p className={"heuristicTitle"}>{props.detail}</p>
    </div>
  );
}

function Card(props: {
  icon?: string,
  title: string,
  content: string,
  className?: string
}) {

  const className = props.className ? "card " + props.className : "card"

  return (
    <div className={className}>
      {props.icon ? <div className={"cardIconRow"}>
        <img className={"cardIcon"} src={props.icon} alt={"icon"}/>
        <h2 className={"cardTitle whiteColor"}>{props.title}</h2>
      </div> : <h2 className={"cardTitle whiteColor"}>{props.title}</h2>}
      <div className={"t24"}>
        <p className={"folioBodyTextBold whiteColor"}>{props.content}</p>
      </div>
    </div>
  );
}


function LargeCard(props: {
  title: string, content
    :
    string, className?: string
}) {

  const className = props.className ? "largeCard " + props.className : "largeCard"

  return (
    <div className={className}>
      <h2 className={"folioSubheading textAlignCenter"}>{props.title}</h2>
      <div className={"t24"}>
        <p className={"folioBodyText t24"}>{props.content}</p>
      </div>
    </div>
  );
}

function BulletPoint(props: {
  white: boolean,
  children: ReactNode
}) {

  const paragraphClass = props.white ? "folioBodyText whiteColor" : "folioBodyText"
  const bulletImage = props.white ? "BulletWhite.png" : "Bullet.png"

  return (
    <div className={"bulletPoint centerFlex"}>
      <img className={"bullet"} src={bulletImage} alt={"bullet point"}/>
      <p className={paragraphClass}>{props.children}</p>
    </div>
  );
}

function BulletPointWithTitle(props: {
  title: string, children: ReactNode
}) {
  return (
    <div className={"bulletPoint"}>
      <img className={"bulletLg"} src={"BulletPurple.png"} alt={"bullet point"}/>
      <div>
        <h1 className={"folioSubheading"}>{props.title}</h1>
        <div className={"t24"}>
          <p className={"folioBodyText"}>{props.children}</p>
        </div>
      </div>
    </div>
  );
}

export default Goodreads;