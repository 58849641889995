import React, { ReactNode } from "react";
import { Footer, Logo } from "./App";
import './shopify.css';
import "react-image-gallery/styles/css/image-gallery.css";

function Shopify() {

  const finalSectionRef = React.useRef<HTMLDivElement>(null);

  return (
    <div className="Recipease">
      <nav className={"nav"}>

        <div className={"navContents"}>
          <Logo/>

          <div className={"navLinks"}>
            <a className={"textButton"} href={"/#about_me"}>About</a>
            <a className={"textButton"} href={"/#work"}>Work</a>
            <a className={"textButton"} href={"mailto:yekatsuras@gmail.com"}>Contact</a>
          </div>
        </div>
      </nav>

      <div className={"rpTopSection"}>
        <img className={"fullSpan fullWidth topSectionImage"} src={"Editify.png"}
             alt={"header showing the Recipease logo and mockups"}/>
        <img className={"downArrowIndicator desktopOnly"} src={"DownArrowGreen.png"} alt={"down arrow"} onClick={() => {
          window.scrollTo({ top: window.innerHeight * 0.8, behavior: 'smooth' })
        }}/>
      </div>

      <div className={"mainGrid rpSection"}>
        <div className={"grid36 t120"}>
          <p className={"sectionHeading"}>Overview</p>

          <div className={"t40"}>
            <p className={"folioBodyText"}>Given 24 hours, our team of 1 Data Scientist, 2 Software Developers, and 3 UX
              Designers were challenged to develop a solution for one of the top e-commerce platforms in the world,
              Shopify.
              <br/><br/>
              The solution: an app extension that allows customers to edit their order within 2 hours of placement to
              rectify any mistakes, add more product, and save merchants on time lost providing customer support.</p>
          </div>
        </div>

        <div className={"grid813 t120 l32 studyTags"}>
          <p className={"sectionHeading transparent desktopOnly"}>.</p>
          <div className={"cardFitted greenShopifyBackground t40"}>
            <p className={"folioBodyText"}><span className={"folioBodyTextBold"}>Role:</span> UX Designer
            </p>
          </div>
          <div className={"cardFitted greenShopifyBackground t24"}>
            <p className={"folioBodyText"}><span className={"folioBodyTextBold"}>Project Type:</span> Hackathon</p>
          </div>

          <div className={"cardFitted greenShopifyBackground t24"}>
            <p className={"folioBodyText"}><span className={"folioBodyTextBold"}>Duration:</span> 24 hours</p>
          </div>
        </div>

        <div className={"fullSpan"}>
          <a onClick={() => {
            finalSectionRef.current?.scrollIntoView({ behavior: 'smooth' })
          }}
             className={"button smallButton t100 mauto"}>View Final Product</a>
        </div>
      </div>

      <div className={"rpSection mainGrid"}>
        <div className={"fullSpan headingBanner t100 greenShopifyBackground mainGrid mainGridNoPadding"}>
          <h1 className={"sectionHeading gridOffset1"}>The Client</h1>
        </div>
      </div>

      <div className={"mainGrid rpSection"}>
        <div className={"grid80 t120"}>

          <img style={{ width: "210px" }} src={"ShopifyLogo.png"} alt={"Shopify logo"}/>

          <div className={"t80"}>
            <p className={"folioBodyText "}>Shopify is the
              <span className={"folioBodyTextBold"}> leading e-commerce platform in the world</span> , providing
              e-commerce tools for merchants to easily sell their merchandise online and in stores. They are a<span
                className={"folioBodyTextBold"}> multibillion-dollar company, with a dominating market share in the United States.</span> Millions
              of stores use Shopify, including Allbirds, Gym Shark, and Supreme, among countless other leading brands.
            </p>
          </div>
          <div className={"cardSet t80"}>
            <Card className={"greenDarkBackground"} title={"2.8M +"} content={"Stores Worldwide"}/>
            <Card className={"greenDarkBackground"} title={"67%"} content={"Market share in the U.S."}/>
            <Card className={"greenDarkBackground"} title={"$7.1B"} content={"Revenue in 2023"}/>
          </div>
        </div>
      </div>

      <div className={"rpSection mainGrid"}>
        <div className={"fullSpan headingBanner t100 greenShopifyBackground mainGrid mainGridNoPadding"}>
          <h1 className={"sectionHeading gridOffset1"}>The Problem</h1>
        </div>
      </div>

      <div className={"rpSection mainGrid"}>
        <div className={"grid80 t120"}>
          <p className={" folioBodyText "}>Shopify merchants <span className={"folioBodyTextBold"}> spend too much time supporting customers managing their orders instead of growing their business.</span>

            <br/><br/><br/><span className={"folioBodyTextBold"}>Merchants want to:</span>
          </p>
        </div>

        <div className={"grid50 t80 bulletGroupSm"}>
          <div className={"greenDarkerBackground pa40 borderRadius"}>
            <BulletPoint white={true}><span className={"folioBodyTextBold"}>Put capabilities into their customers’ hands that will help cut down on support requests</span></BulletPoint>
          </div>
          <div className={"greenDarkerBackground pa40 borderRadius"}>
            <BulletPoint white={true}><span className={"folioBodyTextBold"}>Provide a trusted customer account experience for their customers</span></BulletPoint>
          </div>
          <div className={"greenDarkerBackground pa40 borderRadius"}>
            <BulletPoint white={true}><span className={"folioBodyTextBold"}>Supply customers with capabilities that would help sell more products</span></BulletPoint>
          </div>
        </div>

        <div className={"grid80 t120"}>
          <p className={" folioBodyText "}>Our team of <span className={"folioBodyTextBold"}>1 data scientist, 2 software engineers, and 3 UX designers</span>,
            were given <span className={"folioBodyTextBold"}>24 hours</span> to find a solution to the time management
            issue facing Shopify merchants. We needed a solution that would <span className={"folioBodyTextBold"}>drive down support requests and encourage more sales without negatively affecting customer service.</span>
          </p>
        </div>
      </div>

      <div className={"fullWidth greenDarkBackground t120"}>
        <h1 className={"sectionHeading howMightWe whiteColor"}>What kind of app experiences could you create that enable
          merchants to offer self-service options to their customers that reduce support requests or boost sales?</h1>
      </div>

      <div className={"rpSection mainGrid"}>
        <div className={"grid80 t120"}>
          <p className={" folioBodyText "}>We took an <span className={"folioBodyTextBold"}>agile approach</span> to
            this challenge, brainstorming and trying out various ideas, pivoting when necessary, until the optimal
            solution was reached.
          </p>
        </div>
      </div>

      <div className={"rpSection mainGrid"}>
        <div className={"grid80 t80"}>
          <div className={""}>
            <img className={"fullWidth"} src={"ShopifyTeam.png"}
                 alt={"The team hard at work minutes after receiving our challenge"}/>
          </div>
          <div className={"t20"}>
            <p className={"folioNoteText"}>The team hard at work minutes after receiving our challenge
            </p>
          </div>
        </div>
      </div>

      <div className={"rpSection mainGrid"}>
        <div className={"fullSpan headingBanner t120 greenShopifyBackground mainGrid mainGridNoPadding"}>
          <h1 className={"sectionHeading gridOffset1"}>Research & Groundwork</h1>
        </div>
      </div>

      <div className={"rpSection mainGrid"}>
        <div className={"grid80 t120"}>
          <p className={" folioBodyText "}>Upon receiving our mission, our first step was to conduct foundational
            research on the problem.
            <br/><br/>
            To begin, we discovered that <span className={"folioBodyTextBold"}>apparel is the leading product category across all Shopify stores</span> but
            we wanted
            to delve deeper and understand what makes customers seek out merchant support to get at the root of <span
              className={"folioBodyTextBold"}>what
              kind of support requests are bogging down merchants and taking up their time.</span>
            <br/><br/>
            We learned that some of the most common reasons are:
          </p>


          <div className={"cardSet t80"}>
            <p className={"folioBodyTextBold whiteColor width100 pillContainer greenDarkerBackground"}>Returns &
              Exchanges</p>
            <p className={"folioBodyTextBold whiteColor width100 pillContainer greenDarkerBackground"}>Product
              Information</p>
            <p className={"folioBodyTextBold whiteColor width100 pillContainer greenDarkerBackground"}>Billing &
              Payments</p>
          </div>

          <div className={"t80"}>
            <p className={" folioBodyText "}>Now that we had established what was taking up merchant time, we sought to
              dig into what issues were causing these requests and <span
                className={"folioBodyTextBold"}>reasons that were impeding customers from solving them without merchant intervention.</span> According
              to the Baymard Institute’s large-scale checkout usability testing:
            </p></div>

          <div className={"cardSet t80"}>
            <Card className={"greenDarkBackground width100"} title={"80%"}
                  content={"of stores don’t provide users with separate edit links for all distinct information groups (shipping, billing, order details) "}/>
            <Card className={"greenDarkBackground width100"} title={"49%"}
                  content={"of stores don’t allow users to edit order information directly at the review step"}/>
          </div>

          <div className={"t80"}>
            <p className={" folioBodyText "}>What’s more, they noted that by tackling these key checkout usability
              issues, merchants can see a:
            </p></div>

          <div className={"cardSet t80"}>
            <Card className={"greenDarkerBackground width100"} icon={"IncreaseArrow.png"} title={"35%"}
                  content={"increase in conversion rates"}/>
            <Card className={"greenDarkerBackground width100"} icon={"DecreaseSupport.png"} title={`\xa0`}
                  content={"reduction in support requests"}/>
          </div>

          <div className={"t80"}>
            <p className={" folioBodyText "}>Referencing our new insights, we refined our initial challenge to help
              direct us to a more focused solution as we began to develop our ideas.
            </p></div>


        </div>
      </div>

      <div className={"fullWidth greenDarkBackground t120"}>
        <h1 className={"sectionHeading howMightWe whiteColor"}>How might we create a self-service customer account
          experience (app) that enhances merchant productivity and revenue?</h1>
      </div>

      <div className={"rpSection mainGrid"}>
        <div className={"grid80 t120"}>
          <p className={" folioBodyText "}>We also crafted 2 <span
            className={"folioBodyTextBold"}>user personas</span> that we could reference as we entered our solution
            design phase so that our user’s needs were at the forefront of our minds.
          </p>

          <img className={"fullWidth t80"} src={"Renee.png"}
               alt={"The persona for Renee"}/>

          <img className={"fullWidth t40"} src={"Alyssa.png"}
               alt={"The persona for Alyssa"}/>
        </div>
        <div className={"grid80 t120"}>
          <p className={" folioBodyText "}>Having set our foundation, we began to craft out our solution. Our research
            made it evident that there were usability issues during the checkout stage for customers that directly
            affect merchant’s bottom line and cost them time. If we tackled them, it would benefit both Shopify
            merchants and customers: an excellent answer to our How Might We?
          </p>
        </div>
      </div>

      <div className={"rpSection mainGrid"} ref={finalSectionRef}>
        <div className={"fullSpan headingBanner t120 greenShopifyBackground mainGrid mainGridNoPadding"}>
          <h1 className={"sectionHeading gridOffset1"}>Our Solution</h1>
        </div>
      </div>

      <img className={"fullWidth t120"} src={"ShopifySolution.png"}
           alt={"The persona for Alyssa"}/>

      <div className={"rpSection mainGrid"}>
        <div className={"grid80 t120"}>
          <p className={" folioBodyText "}>Customers sometimes make mistakes or change their minds. Mistakes are natural
            and inevitable but needn’t be as costly and inconvenient as they presently are for customers and merchants
            alike.
            <br/><br/>
            Editfy is our solution, an extensive capability (app) that Shopify merchants can add to their storefronts,
            allowing their customers to edit their orders within a 2 hour timeframe. Customers can change size,
            quantities, and shipping information, as well as add product to orders. This means that
            <span
              className={"folioBodyTextBold"}>merchants won’t lose time or money rectifying mistakes that customers can easily manage themselves and have more time to focus on other aspects of their business.</span>
            <br/><br/>
            Utilizing Shopify’s design system, we demonstrated Editfy in action from the perspective of a customer
            editing a placed order’s quantity and size.
          </p>
        </div>
      </div>

      <div className={"rpSection mainGrid"}>
        <div className={"fullSpan headingBanner t120 greenShopifyBackground mainGrid mainGridNoPadding"}>
          <h1 className={"sectionHeading gridOffset1"}>Key Learnings</h1>
        </div>
      </div>

      <div className={"rpSection mainGrid"}>
        <div className={"grid80 t120"}>
          <div className={"bulletGroup"}>
            <BulletPointWithTitle title={"Data precedes design"}>It is essential to design based on the data, not mould
              the data to your design after the fact. Given the short amount of time, it was easy to make the error of
              jumping right into design without forethought but the result would be a useless product that serves no
              one. This was a huge lesson.</BulletPointWithTitle>
            <BulletPointWithTitle title={"Advocate for your user"}>As a designer you need to make sure that the end
              product serves your user’s needs. Again, the time crunch pressured us into wanting to quickly venture down
              different avenues but it was important to stop and ensure that we proceeded with an idea that would
              actually be a solution to the HMW.</BulletPointWithTitle>
            <BulletPointWithTitle title={"Trust your team"}>Being a good designer means knowing when to speak up and
              advocate for your user, and knowing when to support your group members and trust them to do their
              job. </BulletPointWithTitle>
          </div>
        </div>
      </div>

      <div className={"rpSection mainGrid"}>
        <div className={"fullSpan headingBanner t120 greenDarkerBackground mainGrid mainGridNoPadding"}>
          <h1 className={"sectionHeading gridOffset1 whiteColor"}>Thanks for your time!</h1>
        </div>
      </div>

      <Footer/>

      <div className={"t80"}></div>
    </div>
  );
}

function Card(props: {
  icon?: string,
  title: string,
  content: string,
  className?: string
}) {

  const className = props.className ? "card " + props.className : "card"

  return (
    <div className={className}>
      {props.icon ? <div className={"cardIconRow"}>
        <img className={"cardIcon"} src={props.icon} alt={"icon"}/>
        <h2 className={"cardTitle whiteColor"}>{props.title}</h2>
      </div> : <h2 className={"cardTitle whiteColor"}>{props.title}</h2>}
      <div className={"t24"}>
        <p className={"folioBodyTextBold whiteColor"}>{props.content}</p>
      </div>
    </div>
  );
}


function LargeCard(props: {
  title: string, content
    :
    string, className?: string
}) {

  const className = props.className ? "largeCard " + props.className : "largeCard"

  return (
    <div className={className}>
      <h2 className={"folioSubheading textAlignCenter"}>{props.title}</h2>
      <div className={"t24"}>
        <p className={"folioBodyText t24"}>{props.content}</p>
      </div>
    </div>
  );
}

function BulletPoint(props: {
  white: boolean,
  children: ReactNode
}) {

  const paragraphClass = props.white ? "folioBodyText whiteColor" : "folioBodyText"
  const bulletImage = props.white ? "BulletWhite.png" : "Bullet.png"

  return (
    <div className={"bulletPoint centerFlex"}>
      <img className={"bullet"} src={bulletImage} alt={"bullet point"}/>
      <p className={paragraphClass}>{props.children}</p>
    </div>
  );
}

function BulletPointWithTitle(props: {
  title: string, children: ReactNode
}) {
  return (
    <div className={"bulletPoint"}>
      <img className={"bulletLg"} src={"BulletGreen.png"} alt={"bullet point"}/>
      <div>
        <h1 className={"folioSubheading"}>{props.title}</h1>
        <div className={"t24"}>
          <p className={"folioBodyText"}>{props.children}</p>
        </div>
      </div>
    </div>
  );
}

export default Shopify;