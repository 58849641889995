import React from "react";
import { Footer, Logo } from "./App";
import './superpatch.css';
import "react-image-gallery/styles/css/image-gallery.css";

function Superpatch() {

  return (
    <div className="Superpatch">
      <nav className={"nav"}>

        <div className={"navContents"}>
          <Logo/>

          <div className={"navLinks"}>
            <a className={"textButton"} href={"/#about_me"}>About</a>
            <a className={"textButton"} href={"/#work"}>Work</a>
            <a className={"textButton"} href={"mailto:yekatsuras@gmail.com"}>Contact</a>
          </div>
        </div>
      </nav>

      <div className={"rpTopSection"}>
        <img className={"fullSpan fullWidth"} src={"SuperpatchHeader.png"}
             alt={"header showing the Superpatch logo and photos"}/>
        <img className={"downArrowIndicator desktopOnly"} src={"DownArrow.png"} alt={"down arrow"}
             onClick={() => {
               window.scrollTo({ top: window.innerHeight * 0.8, behavior: 'smooth' })
             }}/>
      </div>

      <div className={"mainGrid rpSection"}>
        <div className={"grid36 t120"}>
          <p className={"sectionHeading"}>Overview</p>

          <div className={"t40"}>
            <p className={"folioBodyText"}>My photography services were commissioned by The Super Patch Company to
              showcase their new line of patches.</p>
          </div>
        </div>

        <div className={"grid713 t120 l32 studyTags"}>
          <div className={"cardFitted orangeBackground"}>
            <p className={"folioBodyText"}><span className={"folioBodyTextBold"}>Role:</span> Photographer
            </p>
          </div>
          <div className={"cardFitted pinkBackground t24"}>
            <p className={"folioBodyText"}><span className={"folioBodyTextBold"}>Project Type:</span> Freelance
              Study</p>
          </div>

          <div className={"cardFitted yellowBackground t24"}>
            <p className={"folioBodyText"}><span className={"folioBodyTextBold"}>Duration:</span> 2 days</p>
          </div>
        </div>

      </div>


      <div className={"mainGrid rpSection"}>
        <div className={"grid90 t120"}>
          <div className={"flexRow noGap"}>
            <img className={"fullWidth fullHeight cover"} src={"Superpatch4.jpg"} alt={"Superpatch edited image"}/>
            <img className={"fullWidth fullHeight cover"} src={"Superpatch10.jpg"} alt={"Superpatch edited image"}/>
          </div>
          <div className={"flexRow3 noGap"}>
            <img className={"fullWidth fullHeight cover"} src={"Superpatch7.jpg"} alt={"Superpatch edited image"}/>
            <img className={"fullWidth fullHeight cover"} src={"Superpatch6.jpg"} alt={"Superpatch edited image"}/>
            <img className={"fullWidth fullHeight cover"} src={"Superpatch8.jpg"} alt={"Superpatch edited image"}/>
          </div>
          <div className={"flex"}>
            <img className={"fullWidth fullHeight cover"} src={"Superpatch9.jpg"} alt={"Superpatch edited image"}/>
          </div>
          <div className={"flex"}>
            <img className={"fullWidth fullHeight cover"} src={"Superpatch5.jpg"} alt={"Superpatch edited image"}/>
          </div>
          <div className={"flexRow3 noGap"}>
            <img className={"fullWidth fullHeight doubleColWidth cover"} src={"Superpatch1.jpg"}
                 alt={"Superpatch edited image"}/>
            <img className={"fullWidth fullHeight cover"} src={"Superpatch2.jpg"} alt={"Superpatch edited image"}/>
          </div>
          <div className={"flex"}>
            <img className={"fullWidth fullHeight cover"} src={"Superpatch3.jpg"} alt={"Superpatch edited image"}/>
          </div>
        </div>
      </div>


      <Footer/>

      <div className={"t80"}></div>
    </div>
  );
}

export default Superpatch;